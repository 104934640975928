<script setup lang="ts">
import type { jobs, clients } from "@prisma/client";
import qs from "qs";
type JobWithClients = jobs & { client: clients };

const props = withDefaults(
  defineProps<{
    omit?: (
      | "company"
      | "location"
      | "radius"
      | "keywords"
      | "driverType"
      | "freightTypes"
      | "routeTypes"
    )[];
    clientId?: string;
    location?: string;
  }>(),
  {
    // @ts-ignore this is dumb
    omit: [],
  }
);

const page = ref(Number(useRoute().query.page || 1));
const filters = ref({
  include: ["client", "locations"],
  perPage: Number(useRoute().query.perPage || 20),
  published: true,
  ...useRoute().query,
  clientId: useRoute().query.clientId || props.clientId,
  location: useRoute().query.location || props.location,
});

const url = computed(
  () => `/api/jobs/?${qs.stringify({ ...filters.value, page: page.value })}`
);
const allJobs = ref<JobWithClients[]>([]);

const {
  data: jobs,
  refresh,
  pending,
} = await useFetch<JobWithClients[]>(url, {
  key: "jobs",
});

const jobsDebounced = refDebounced(allJobs, 500);

allJobs.value = jobs.value || [];

watch(page, async () => {
  if (handlingFilterChange.value) return;
  await refresh();
  allJobs.value = allJobs.value.concat(jobs.value || []);
});

const handlingFilterChange = ref(false);
async function handleFilterChange(e: Partial<typeof filters.value>) {
  handlingFilterChange.value = true;
  filters.value = { ...filters.value, ...e };

  // update the app query string with the form values
  useRouter().push({ query: { ...useRoute().query, ...filters.value } });
  page.value = 1;
  await refresh();
  allJobs.value = jobs.value || [];
  handlingFilterChange.value = false;
}
</script>

<template>
  <div class="md:grid md:grid-cols-12 grid-cols-1 gap-[30px]">
    <div class="lg:col-span-3 md:col-span-4">
      <JobSearchForm
        class="md:sticky top-20"
        :omit="omit"
        :filters="filters"
        @change="handleFilterChange"
      />
    </div>

    <div class="lg:col-span-9 md:col-span-8">
      <slot name="results-top"></slot>

      <JobGrid
        :jobs="allJobs.length ? allJobs : jobsDebounced"
        :loading="pending"
        @end="page++"
      />
      <slot name="results-bottom"></slot>
    </div>
  </div>
</template>
