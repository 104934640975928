<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
// define props
const props = withDefaults(
  defineProps<{
    filters?: Record<string, any>;
    omit?: (
      | "company"
      | "location"
      | "radius"
      | "keywords"
      | "driverType"
      | "freightTypes"
      | "routeTypes"
    )[];
  }>(),
  {
    // @ts-ignore this is dumb
    omit: [],
  }
);
// define component emits
const emit = defineEmits<{
  (e: "change", payload: typeof form.value): void;
}>();

const {
  freightTypes,
  routeTypes,
  experience,
  licenseClass,
  endorsements,
  driverType,
} = useAppConfig();

// Form data
const form = ref({
  ...props.filters,
});

// load companies for the select
const { data: companies } = await useFetch("/api/companies", {
  query: { isAgency: false, take: 50 },
  lazy: true,
  default: () => [],
});

const autoEmit = computed(() => {
  let f = form.value;

  return [f.driverType, f.clientId, f.freightTypes, f.routeTypes];
});
setTimeout(() => {
  watch(
    autoEmit,
    (newValue, oldValue) => {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        handleChange();
      }
    },
    { deep: true }
  );
}, 1000);

const { states } = useAppConfig();
function handleChange() {
  const st = states.find(
    (state) => state[1].toLowerCase() === form.value.location?.toLowerCase()
  );
  if (st) form.value.location = st[0];
  form.value.radius = form.value.radius || 0;
  emit("change", form.value);
}

const show = ref(false);

const showRadius = computed(
  () =>
    isZip(form.value.location) || !isStateNameOrStateCode(form.value.location)
);

const { primaryColor } = usePrimaryColor();
</script>
<template>
  <div>
    
<FormKitLazyProvider config-file="true">
<div
      :class="{
        'pointer-events-none hidden md:pointer-events-auto': !show,
      }"
      class="h-[90%] md:h-auto overflow-scroll fixed bottom-0 left-0 z-10 w-full p-6 rounded-md shadow bg-base-100 dark:shadow-gray-700 dark:bg-slate-900 md:top-20 md:sticky md:block"
    >
      <slot name="header"></slot>

      <h2 class="text-sm font-bold text-center mb-7">
        Search Truck Driving Jobs
      </h2>

      <div class="grid grid-cols-1 gap-3 fields-no-outline">
        <div>
          <FormKit
            type="form"
            v-model="form"
            :actions="false"
            @submit="handleChange"
          >
            <!-- Location -->
            <div class="flex items-center">
              <FormKit
                v-if="!omit.includes('location')"
                label="Location"
                name="location"
                type="location"
                placeholder="City, State, or Zip"
                outer-class="w-full"
              >
                <template #suffixIcon>
                  <button class="p-2 rounded-none">
                    <IconArrowRightCircleOutline class="w-5" />
                  </button>
                </template>
              </FormKit>
            </div>

            <FormKit
              v-if="!omit.includes('radius')"
              :disabled="!showRadius"
              label="Radius"
              name="radius"
              type="number"
              :value="50"
              outer-class="w-full"
            />

            <!-- Keywords -->
            <div class="flex items-center">
              <FormKit
                v-if="!omit.includes('keywords')"
                label="Keywords"
                name="keywords"
                type="text"
                outer-class="w-full"
              >
                <template #suffixIcon>
                  <button class="p-2 rounded-none">
                    <IconArrowRightCircleOutline class="w-5" />
                  </button>
                </template>
              </FormKit>
            </div>

            <!-- Driver Type -->
            <FormKit
              v-if="!omit.includes('driverType')"
              label="Driver Type"
              type="taglist"
              name="driverType"
              :options="driverType"
            />

            <FormKit
              v-if="!omit.includes('freightTypes')"
              label="Freight Types"
              type="taglist"
              name="freightTypes"
              :options="freightTypes"
            />

            <FormKit
              v-if="!omit.includes('routeTypes')"
              label="Route Type"
              name="routeTypes"
              type="taglist"
              :options="routeTypes"
            />

            <!-- State -->
            <!-- <AppStateSelect label="State" type="taglist" /> -->

            <!-- City -->
            <!-- <FormKit type="text" label="City" name="city">
              <template #suffixIcon>
                <button class="p-2 rounded-none">
                  <IconArrowRightCircleOutline class="w-5" />
                </button>
              </template>
            </FormKit> -->

            <!-- Company -->
            <FormKit
              v-if="!omit.includes('company')"
              label="Company"
              type="taglist"
              name="clientId"
              :options="
                companies?.map((company) => ({
                  label: company.name,
                  value: company.id,
                }))
              "
            />
            <button
              :style="{
                backgroundColor: primaryColor,
              }"
              class="w-full text-white btn"
              @click="show = false"
            >
              Search
            </button>
          </FormKit>
        </div>
      </div>
    </div>
    <button
      class="fixed z-20 p-3 border-2 border-white rounded-full shadow-lg md:hidden bottom-2 right-2 bg-primary"
      @click="show = !show"
    >
      <IconAdjustmentsHorizontal class="w-5 h-5 text-white" />
    </button>
</FormKitLazyProvider>

  </div>
</template>
