<script setup lang="ts">
import type { jobs, clients } from "@prisma/client";
type JobWithClient = jobs & { client: clients };

defineProps<{
  jobs: JobWithClient[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "end", payload: void): void;
}>();

const end = ref();
onMounted(() => {
  setTimeout(() => {
    const { stop } = useIntersectionObserver(
      end,
      ([{ isIntersecting }], observerElement) => {
        if (isIntersecting) {
          emit("end");
        }
      }
    );
  }, 1000);
});
</script>
<template>
  <div
    :class="{
      'opacity-50': loading && jobs.length,
      'transition-all duration-1000': true,
    }"
  >
    <template v-if="jobs.length">
      <div class="grid lg:grid-cols-2 gap-[30px]">
        <JobGridItem v-for="job in jobs" :job="job" />
      </div>
      <div ref="end" class="mt-[-100px] pointer-events-none"></div>
    </template>
    <template v-else>
      <div
        class="flex flex-col items-center justify-center p-10 bg-gray-100 md:h-full"
      >
        <IconNoSymbol class="w-20 h-20 mx-auto text-gray-400" />

        <p class="text-lg opacity-50">
          No jobs are available matching those filters
        </p>
        <p class="text-sm opacity-50">
          Please refine your search and try again
        </p>
      </div>
    </template>
  </div>
</template>
